export var SearchList = [
  { labe: '地址名称', code: 'addressName', type: 'input' },
  { labe: '地质属性', code: 'addressProperty', type: 'input' }
]
export var SearchData = {
  addressName: '',
  addressProperty: ''
}
export var tableField = [
  { label: '编码', type: 'input', width: '50px', code: 'werks' },
  { label: '仓库名称', code: 'lgort', type: 'input', width: '' },
  { label: '所属基地', type: 'input', width: '', code: 'calca' },
  { label: '所属工厂', code: 'werks', type: 'input', width: '' },
  { label: '备注', code: '', type: 'input', width: '' }
]
