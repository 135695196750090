<template>
    <div class="list">
        <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
        <global-table
        :tableField="tableField"
        :tableData="tableData"
        :paginationData="paginationData"
        @pageChange="pageChange"
        @tableAction="tableAction"
        ></global-table>
        <dialog-box ref="dialogbox" dialogWidth="50%" :dialogShow="dialogShow" :formData = "showData" componentName="AddAddress"
                @handleClose='handleClose' :title="title"
                :buttonData="buttonData" @action="action" ></dialog-box>
    </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField } from './js/setting.js'
export default {
  name: 'WareHouse',
  components: Component.components,
  data: function () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      childData: { searchList: SearchList, searchData: SearchData },
      menudata: this.$store.state.ViewMenuData.menuData,
      tableField: tableField,
      tableData: [],
      paginationData: { pageSize: pageSize, total: 1 },
      searchdata: {},
      dialogShow: false,
      title: '添加地址',
      showData: {},
      pageSize: pageSize,
      buttonData: [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'action', size: 'mini', type: 'primary' }]
    }
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  },
  created: function () {
    request('/api/sap/sapInventoryLocation/getSapInventoryLocationList', 'get').then((Response) => {
      // eslint-disable-next-line eqeqeq
      if (Response.code == '200') {
        this.tableData = Response.data
        // this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
      }
    })
  },
  methods: {
    tableAction: function (index, data, act) {
      if (act.id === '10') {
        this.title = '编辑'
        this.dialogShow = true
        this.showData = data
        this.buttonData = [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'action', size: 'mini', type: 'primary' }]
      }
      if (act.id === '11') {
      }
    },
    action: function () {
      request('/api/supplier/basicData/saveSupplierAddress', 'post', this.$refs.dialogbox.$refs.content.form).then((Response) => {
        if (Response.code === '200') {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.pageChange(1)
        }
      })
      this.dialogShow = false
    },
    handleClose: function () {
      this.dialogShow = false
    },
    buttonClick: function () {
      this.title = '新增'
      this.dialogShow = true
      this.showData = {}
      this.buttonData = [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'action', size: 'mini', type: 'primary' }]
    },
    pageChange: function (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      request('/api/supplier/basicData/addressManagement', 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    },
    search: function (data) {
      data.pageNum = 1
      data.pageSize = this.pageSize
      this.searchdata = data
      request('/api/supplier/basicData/addressManagement', 'get', data).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
